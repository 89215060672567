<template>
  <div v-bind="attrs">
    <!-- Header -->
    <header class="user-order-card__header">
      <article class="user-order-card__bag">
        <div class="user-order-card__qty">{{ itemsCount }}</div>
        <div class="user-order-card__amount">{{ totalPrice }}</div>
      </article>
    </header>

    <!-- Body -->
    <section class="user-order-card__body">
      <article class="user-order-card__first">

        <!-- FL / Shop -->
        <article class="user-order-card__shop user-order-card__row">
          <div class="user-order-card__illustration">
            <ui-img
              alt="Shop image"
              class="user-order-card__figure"
              :src="logo"
            />
          </div>

          <div class="user-order-card__data">
            <div class="user-order-card__label">{{ name }}</div>
            <address class="user-order-card__address">
              <ui-icon glyph="location"/>
              <span>{{ orderAddress }}</span>
            </address>
          </div>
        </article>

        <!-- Service -->
        <article class="user-order-card__row">
          <div class="user-order-card__illustration">
            <ui-icon
              class="user-order-card__icon"
              :glyph="service"
            />
          </div>

          <div class="user-order-card__data">
            <div class="user-order-card__label">{{ $t(`resto.catalog_services_${service}`)}}</div>
            <div
              class="user-order-card__sublabel"
              v-if="isDelivery"
            >{{ deliveryAddress }}</div>
          </div>
        </article>

        <!-- Date -->
        <article class="user-order-card__row">
          <div class="user-order-card__illustration">
            <ui-icon
              class="user-order-card__icon"
              glyph="time"
              />
          </div>

          <div class="user-order-card__data">
            <!-- Not completed order -->
            <div
              class="user-order-card__label"
              v-if="!completionDate"
            >{{ $t(`resto.user_order_creationdate`, {date: creationDate}) }}</div>

            <!-- Completed order -->
            <div
              class="user-order-card__label"
              v-if="completionDate && isAsap"
            >{{ $t(`resto.user_order_is_asap`) }}</div>

            <div
              class="user-order-card__label"
              v-if="completionDate && !isAsap"
            >{{ $t(`resto.user_order_${service}_date`, {date: completionDate}) }}</div>

            <div
              class="user-order-card__sublabel"
              v-if="completionDate"
            >{{ $t(`resto.user_order_creationdate`, {date: creationDate}) }}</div>
          </div>
        </article>
      </article>

      <article class="user-order-card__second">
        <!-- Order num -->
        <div class="user-order-card__ordernum">{{ $t('resto.order_code', { code }) }}</div>

        <!-- Order detail -->
        <actions-button
          appearance="primary"
          size="s"
          @click.stop.prevent="onViewOrder"
        >{{ $t('resto.user_view_order_detail')}}</actions-button>

        <!-- Reorder -->
        <actions-button
          size="s"
          v-if="false"
        >{{ $t('resto.user_reorder') }}</actions-button>
      </article>
    </section>
  </div>
</template>

<script>
import { Address } from '@sayl/front-core'
import UiImg from '@/components/ui/img'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'UserOrderCard',

  components: {
    UiImg,
  },

  inject: ['$embed'],

  mixins: [MixinCurrency],

  props: {
    order:{
      type: Object
    }
  },

  computed: {
    attrs() {
      return {
        class: this.classes,
        href: '#',
      }
    },

    classes() {
      return {
        'user-order-card': true
      }
    },

    code() {
      return this.$basil.get(this.order, 'pickup_code', null)
    },

    creationDate() {
      let ret = null;
      let created = this.$basil.get(this.order, 'created')
      if(!this.$basil.isNil(created)) {
        let splits = created.split('/');
        ret = this.$date(new Date(splits[2], splits[1] - 1, splits[0]), 'short')
      }

      return ret;
    },

    completionDate() {
      let ret = null
      if(!this.$basil.isNil(this.orderTimeslot)) {
        let ts = this.$basil.get(this.orderTimeslot, 'timestamp')
        let tz = this.$basil.get(this.orderTimeslot, 'timezone.offset')
        let v = new Date(((ts + tz) + (new Date().getTimezoneOffset() * 60)) * 1000)

        ret = this.$date(v)
      }
      return ret
    },

    deliveryAddress() {
      let ret = this.$basil.get(this.order, 'delivery_address')
      return Address.format(ret).toString()
    },

    flpos() {
      let ret = this.shop.flposes.find(fl => fl.id === this.$basil.get(this.order, 'fulfillment_location_id'))
      return ret
    },

    isAsap() {
      return this.$basil.get(this.order, 'pickup_asap', false)
    },

    isEatin() {
      return this.$basil.get(this.order, 'service', null) === 'eatin'
    },

    isPickup() {
      return this.$basil.get(this.order, 'service', null) === 'base'
    },

    isDelivery() {
      return this.$basil.get(this.order, 'service', null) === 'delivery'
    },

    itemsCount() {
      let items = this.$basil.get(this.order, 'order_items', []);
      return items.length;
    },

    logo() {
      return this.$basil.get(this.shop, 'logo')
    },

    name() {
      return this.$basil.get(this.fl, 'name') ||
             this.$basil.get(this.shop, 'name');
    },

    orderAddress() {
      return this.flpos && this.flpos.address || this.shop && this.shop.address
    },

    orderTimeslot() {
      return this.$basil.get(this.order, 'timeslot', null)
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    service() {
      let ret = this.$basil.get(this.order, 'service', null)
      if (ret === 'base'){
        ret = 'pickup'
      }

      return ret
    },

    totalPrice() {
      let ret = this.$basil.get(this.order, 'price_total', 0);
      return this.toCurrency(ret)
    }
  },

  methods: {
    onViewOrder() {
      this.$router.push({ name: 'sayl-front-user.order', params: { id: this.order.id } })
        .catch((e) => {})
    }
  }
}
</script>
