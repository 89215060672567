<template>
  <section class="card-loyalty">
    <div class="card-loyalty__summary">
      <div class="card-loyalty__labels">
        <div class="card-loyalty__header">
          <div class="card-loyalty__figure">
            <ui-icon glyph="award" />
          </div>

          <h4 class="card-loyalty__title">{{ name }}</h4>
        </div>

        <p class="card-loyalty__balance">{{ $t('resto.user_card_loyalty_balance')}} <span class="card-loyalty__value">{{ pointsLabel }}</span></p>
        <p 
          class="card-loyalty__balance card-loyalty__tier"
          v-if="tier"
        >{{ $t('resto.user_card_loyalty_tier')}} <span class="card-loyalty__value">{{ tierName }}</span></p>
      </div>

      <div
        class="card-loyalty__actions"
        v-if="cta">
        <actions-button
          class="card-loyalty__action"
          :href="action.route"
          icon-post="arrow-right"
          :key="`loyalty_action_${i}`"
          :size="$pepper.Size.S"
          v-for="(action, i) in actions"
        >{{ action.label }}</actions-button>
      </div>
    </div>

    <card-wallet-pass v-if="hasDigitalCards" />
  </section>
</template>

<script>
import MixinCurrency from '@/helpers/currency'
import CardWalletPass from './card-wallet-pass.vue'

export default {
  name:'UserCardLoyalty',

  components: {
    CardWalletPass
  },

  mixins:[
    MixinCurrency,
  ],

  inject: [
    '$core',
    '$loyalty',
    '$user',
  ],

  props: {
    cta: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    actions() {
      return [
        {
          label: this.$t('resto.user_card_loyalty_balance_history_action'),
          route: { name: 'sayl-front-user.balance.balance' },
        },
        this.hasLoyaltyRedeemableVouchers ? {
          label: this.$t('resto.user_card_loyalty_get_voucher_action'),
          route: { name: 'sayl-front-user.balance.vouchers' },
        } : null,
        this.hasPhysicalCards ? {
          label: this.$t('resto.user_card_loyalty_activate_action'),
          route: { name: 'sayl-front-user.balance.cards' },
        } : null
      ].filter(a => !this.$basil.isNil(a))
    },

    hasDigitalCards() {
      return this.$basil.get(this.$loyalty, 'program.allowDigitalCards', false)
    },

    hasLoyaltyRedeemableVouchers() {
      return this.$basil.get(this.$loyalty, 'program.allowVouchersRedemption', false)
    },

    hasPhysicalCards() {
      return this.$basil.get(this.$loyalty, 'program.allowPhysicalCards', false)
    },

    money() {
      let ret = this.$basil.get(this.$user, 'user.balance.money', null);
      return ret
    },

    moneyLabel() {
      return this.toCurrency(this.money)
    },

    name() {
      return this.$basil.get(this.$loyalty, 'program.name', null)
    },

    points() {
      let ret = this.$basil.get(this.$user, 'user.balance.points', null)
      return !this.$basil.isNil(ret) ? Math.floor(ret) : 0
    },

    pointsLabel() {
      return this.points + this.pointsSymbol
    },

    pointsSymbol() {
      let ret = this.$basil.get(this.$loyalty, 'program.points.symbol', null)
      return ret
    },

    tier() {
      let tiers = this.$basil.get(this.$loyalty, 'program.tiers', [])
      let userTier = this.$basil.get(this.$user, 'user.loyaltyTierId', null)

      if(tiers && tiers.length > 0 && userTier) {
        let ret = tiers.find(t => t.id === userTier)
        if(ret) {
          return ret
        }
      }

      return null
    },

    tierName() {
      return !this.$basil.isNil(this.tier) ? this.$basil.get(this.tier, 'name') : null
    }
  },
}
</script>
