<template>
  <main>
    <div class="container">
      <!-- Greeting -->
      <section class="user-section user-greeting">
        <h1 class="view-user-home__title">{{ $t('resto.user_welcome_message', { name: this.userName })}}</h1>
      </section>

      <section class="user-section -cols">
        <div class="col">
          <!-- Greeting -->
          <section
            class="user-section user-greeting"
            v-if="hasLoyalty">
            <card-loyalty/>
          </section>

          <!-- Latest order -->
          <section class="user-section user-latest">
            <header class="user-section__header">
              <h1 class="user-section__title">{{ $t('resto.user_latest_order') }}</h1>
            </header>

            <div
              class="user-section__body"
              v-if="hasOrder">
              <user-order-card :order="order"/>
            </div>

            <div
              class="user-section__body"
              v-if="!hasOrder">
              <data-empty
                :title="$t('resto.user_latest_order_empty_title')"
                :description="$t('resto.user_latest_order_empty_description')"
              />
            </div>
          </section>
        </div>

        <div class="col">
          <!-- Your account -->
          <section class="user-section user-account">
            <header class="user-section__header">
              <h2 class="user-section__title">{{ $t('resto.user_account_title') }}</h2>
            </header>

            <div class="user-section__body">
              <router-link
                v-for="(child, i) in children"
                :key="i"
                :to="{ name: child.href }"
                class="user-link"
                :class="child.classes"
              >

                <div class="user-link__first">
                  <!-- Icon -->
                  <ui-icon
                    class="user-link__icon"
                    :glyph="child.icon" />

                  <!-- Title -->
                  <span class="user-link__title">{{ child.title }}</span>
                </div>

                <div class="user-link__second">
                  <!-- Description -->
                  <span class="user-link__description">{{ child.description }}</span>
                </div>
              </router-link>
            </div>
          </section>

          <!-- Order again -->
          <section class="user-section user-order">
            <header class="user-section__header">
              <h2 class="user-section__title">{{ $t('resto.user_account_order_again') }}</h2>
            </header>

            <div class="user-section__body">
              <!-- Services -->
              <card-service
                v-for="service in servicesList"
                :disabled="isServiceDisabled(service) || loading"
                :key="service.name"
                :loading="loading"
                :value="service"
                @click="onClickService"
              />
            </div>
          </section>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import UserOrderCard from '../components/ui/card-order'
import { default as CardService } from '@/modules/catalog/components/ui/card-service'
import { default as CardLoyalty } from '../components/ui/card-loyalty'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: "User",

  components: {
    CardLoyalty,
    CardService,
    UserOrderCard
  },

  inject: [
    '$cart',
    '$catalog',
    '$challenge',
    '$core',
    '$embed',
    '$loyalty',
    '$user',
    '$localStorage',
  ],

  mixins: [
    MixinMultishop
  ],

  data(){
    return {
      loading: false,
      vouchers: [],
    }
  },

  computed: {
    ...mapState({
      services: state => state['sayl-front.service'].services,
    }),

    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    children() {
      let ret = [
        {
          title: this.$t('resto.user_order_history_title'),
          description: this.$t('resto.user_order_history_description'),
          icon: 'bag',
          href: 'sayl-front-user.orders',
          classes: '-order-history',
        },
        {
          title: this.$t('resto.user_information_title'),
          description: this.$t('resto.user_information_description'),
          icon: 'user',
          href: 'sayl-front-user.user',
          classes: '-informations',
        },
        {
          title: this.$t('resto.user_addresses_title'),
          description: this.$t('resto.user_addresses_description'),
          icon: 'location',
          href: 'sayl-front-user.addresses',
          classes: '-addresses',
        }
      ]

      if(this.hasLoyalty) {
        ret.push({
          title: this.$t('resto.user_balance_title'),
          description: this.$t('resto.user_balance_description'),
          icon: 'star',
          href: 'sayl-front-user.balance',
          classes: '-loyalty',
        })
      }

      if(this.hasChallenges) {
        ret.push({
          title: this.$t('resto.user_challenges_title'),
          description: this.$t('resto.user_challenges_description'),
          icon: 'flag',
          href: 'sayl-front-user.challenges',
          classes: '-challenges',
        })
      }

      if(this.hasCompany) {
        ret.push({
          title: this.$t('resto.user_company_title'),
          description: this.$t('resto.user_company_description'),
          icon: 'briefcase',
          href: 'sayl-front-user.company',
          slug: 'company',
        })
      }

      if(this.hasVouchers) {
        ret.push({
          title: this.$t('resto.user_vouchers_title'),
          description: this.$t('resto.user_vouchers_description'),
          icon: 'percent',
          href: 'sayl-front-user.vouchers',
          slug: 'vouchers',
        })
      }

      return ret
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    hasBalance() {
      return this.$basil.get(this.user, 'hasBalance', false)
    },

    hasChallenges() {
      return this.$basil.get(this.oat, 'is_challenge_enabled', false) &&
        this.$basil.get(this.$challenge, 'active', false)
    },

    hasCompany() {
      return !!this.$localStorage.company
    },

    hasLoyalty() {
      let excludedShopIds = this.$basil.get(this.$loyalty, 'program.excludedShopIds', [])
      let isShopExcluded = false

      if(!this.$basil.isNil(excludedShopIds) && !this.$basil.isEmpty(excludedShopIds)) {
        isShopExcluded = !this.$basil.isNil(excludedShopIds.find(e => e === this.$basil.get(this.$embed, 'shop.model.id')))
      }

      return [
        !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)),
        !this.isModeTt,
        !isShopExcluded,
        this.hasBalance,
      ].filter(r => r === false).length === 0
    },

    hasOrder() {
      return !this.loading && !this.$basil.isNil(this.order)
    },

    hasVouchers() {
      return !this.loading && this.vouchers.length
    },

    order() {
      return this.$basil.get(this.$user, 'orders.0', null)
    },

    servicesList() {
      return this.services.filter(s => s.name !== 'eatin')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userName() {
      let ret = ''
      let firstname = this.$basil.get(this.user, 'firstname', null)
      let lastname = this.$basil.get(this.user, 'lastname', null)

      if(!this.$basil.isNil(firstname)) {
        ret += `${firstname} `
      }

      if(!this.$basil.isNil(lastname)) {
        ret += `${lastname}`
      }

      return ret
    }
  },

  methods: {
    async init() {
      try {
        let res = await this.$user.getVouchers({})

        this.vouchers = res
      } catch(e) {
        $console.error(e)
      }
    },

    isServiceDisabled(service) {
      return service.name === 'eatin' || !this.hasMenu(service)
    },

    hasMenu(service) {
      return this.$basil.get(service, 'menuId', null) != null
    },

    onClickService(value) {
      this.loading = true

      this.$core.changeService({ service: value.name })
        .then(() => {
          this.$catalog.catalog.catalog = {}

          this.$cart.cart = null
          this.$store.commit('sayl-front.cart/setCart', null)

          this.loading = false
          this.$router.push({
            name: this.isMultiShop ? 'sayl-front-catalog.shops' : 'sayl-front-catalog.catalog',
            params: {
              embed: this.embed.id,
              service: value.name,
            }
          })
          .catch(() => {})
        })
        .catch((e) => $console.error(e))
    },
  },

  mounted() {
    this.loading = true

    if(!this.user || !this.user.isLogged) {
      this.$router.push({name: 'sayl-front-user.login'})
        .catch(() => {})
      return
    }

    this.$user.getOrders({ limit: 1, page: 1 })
      .catch((e) => $console.error(e))
      .finally(() => this.loading = false)

    this.init()
  }
}
</script>
